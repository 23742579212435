import * as React from "react"
import "./mainpage.scss"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Legion from "../components/cases/legion"
import { Row } from "react-bootstrap"
import { graphql } from "gatsby"


const IndexPage = ({location}) => (
  <Layout>
    <div className="max-container-pages">
      <Seo title="Legion" />
      <Row>
        <Legion previous={location.state?.from ?? '/'} ></Legion>
      </Row>
    </div>
  </Layout>
)

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

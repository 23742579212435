import * as React from "react"
import { Container, Col, Row, Button } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import { CloseCase } from "./CloseCase"
import "./legion.scss"


const Legion = ({ className, previous }) => (
    <section className={(className ? className + " " : "") + "legion-case"}>
        <Container fluid>
            <Row className="reverse bg-legion-case-md">

                <Col className="" xl={6} lg={6} md={7} sm={12} xs={12}>
                    <div className="bg-legion-case"></div>
                </Col>

                <Col className="tell-project-legion-case" xl={6} lg={6} md={5} sm={12} xs={12}>
                    <p>
                        <div className="logo-legion-case"></div>
                        <h5 className="">The AI-Powered Labor Platform</h5>

                        <a href="https://legion.co/" target='_blank' rel="noreferrer"><Button className="btn-success">Learn More About Legion</Button></a>

                    </p>
                </Col>
            </Row>

            <div className="max-container-legion">

            <CloseCase referrer={previous} />

                <Row className="reverse-column">
                    <Col className="about-case" lg={6} md={8}>
                        <p>
                            <h6 className="">About Legion</h6>

                            Labor forecasting, automatic scheduling, and employee engagement on a
                            single platform with easy-to-use Web and mobile solutions.

                        </p>
                    </Col>
                </Row>
                <Row className="reverse-column">
                    <Col className="our-case" lg={6} md={8}>
                        <p>
                            <h6 className="">Our Contribution</h6>


                            <h4 className="bullet-culture black-two"> Front-end Development</h4>
                            <h4 className="bullet-culture black-two ">Back-end Development</h4>
                            <h4 className="bullet-culture black-two "> iOS and Android Development</h4>
                            <h4 className="bullet-culture black-two ">  Aided in UX/UI Design</h4>
                            <h4 className="bullet-culture black-two ">QA and Automation</h4>
                        


                        </p>
                    </Col>

                </Row>

                <Row className="reverse-column">
                <Col md={4}>
                    </Col>
                    <Col className="contactus-case" lg={6} md={8}>

                        <h3 className="">Got a Project or Partnership in Mind?</h3>

                        <ButtonContact>Contact Us 	<div className="Polygon-9"></div></ButtonContact>

                    </Col>
                </Row>



            </div>

        </Container>
    </section >
)

export default Legion








